import React from 'react';
import { Link } from 'gatsby';
import AuthNotif from '../../components/AuthNotif/AuthNotif';
import classes from './index.module.scss';
import useGetUserEmail from '../../hooks/useGetUserEmail';

const EmailNotif = ({ location }) => {
  const email = location.state && location.state.email;
  const [userEmail] = useGetUserEmail();

  return (
    <AuthNotif
      email={userEmail?.email || email}
      title="Verification link has been sent to "
    >
      <Link
        to="/resend-email-link"
        className={`${classes.submitBtn} btn btn_md btn-uppercased t-600 btn_block btn btn_common`}
      >
        Resend link
      </Link>
      <div className={classes.infoText}>
        *Verification link valid for 24 hours
      </div>
    </AuthNotif>
  );
};

export default EmailNotif;
