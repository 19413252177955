import { useState, useEffect } from 'react';
import { LOCALSTORAGE_KEYS } from '../constans/localstorage';
import StorageService from '../services/storage.service';

const useGetUserEmail = () => {
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const regUserData = StorageService().get(LOCALSTORAGE_KEYS.REGUSER);
    if (regUserData) {
      setUserEmail(regUserData.email);
    }
  }, []);

  return [userEmail];
};

export default useGetUserEmail;
