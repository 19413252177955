import React from 'react';
import classes from './AuthNotif.module.scss';
import Message from '../../assets/img/icons/ic-message-send.svg';

const AuthNotif = ({
  containerClasses, children, email, title,
}) => (
  <div className="blue-grad-bg center-vertical-page-wrapper center-content-vertical-mobile">
    <div
      className={`${containerClasses} ${classes.container} container-bordered auth-wrapper`}
    >
      <Message className={classes.messageIcon} />
      <div className={`${classes.title} t-500`}>{title}</div>
      <div className={`${classes.emailName} t-600`}>{email}</div>
      {children}
    </div>
  </div>
);

export default AuthNotif;
